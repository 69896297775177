.login-form {
    width: 100%;
    height: 100vh;
    background-image: url(../../assets/background.webp);
    font-family: 'Mulish', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}

.centerv {
    flex-shrink: 0;
    border-radius: 16px;
    background: #FFF;
}

.login-form p {
    color: #1B3665;

}

.btn {
    /* background-color: #1B3665; */
    /* border: none; */
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

.iconBck {
    background-color: #1B3665;
    border: none;
}

Link {
    text-decoration: underline;
    color: #1B3665;

}

