@keyframes unroll {
    0% {
      transform: scaleX(0);
      transform-origin: left;
      opacity: 0;
    }
    50% {
      transform: scaleX(1.05);
      transform-origin: left;
      opacity: 1;
    }
    100% {
      transform: scaleX(1);
      transform-origin: left;
      opacity: 1;
    }
  }
  
  .unroll {
    animation: unroll 0.6s ease-out forwards;
  }